/* eslint-disable no-extra-boolean-cast */
import { memo, useMemo } from "react"
import {
  Button,
  DatePicker,
  Form,
  Select,
  Space,
  Divider,
  Table,
  Tag,
  Steps,
  Tabs,
  Checkbox,
  Modal,
  Typography,
} from "antd"
import type { ColumnsType } from "antd/es/table"
import { SyncOutlined } from "@ant-design/icons"
import { TabsProps } from "antd/lib"
import { format, parseISO } from "date-fns"
import { ptBR } from "date-fns/locale"
import { AppLayout } from ".."
import * as S from "./insert.module"
import { insertBaseUsecase } from "./insert.usecase"
import { CustomUpload } from "../../components"
import { IExtractDataReportHistory, IReport } from "./interfaces"

function InsertBase() {
  const {
    handleChangeDate,
    handleChangeFile,
    handleSubimtReport,
    clearErrors,
    contextHolder,
    handleSelect,
    handleUploadFile,
    values,
    disableCustomUpload,
    handleClientsParticipants,
    reportsType,
    currentStep,
    onSelectChange,
    selectedRow,
    reportDataExtract,
    openModal,
    handleReportSelect,
    setOpenModal,
    consolidateLoading,
    activeTab,
    onChangeTab,
    onChangeConsolidate,
    enableConsolidation,
  } = insertBaseUsecase()

  const selectionColumns: ColumnsType<IExtractDataReportHistory> = [
    {
      render: (_, record) => {
        return (
          <Checkbox
            onClick={() => onSelectChange(record)}
            checked={record.Id === selectedRow?.Id}
          />
        )
      },
    },
    {
      title: "Id",
      dataIndex: "Id",
    },
    {
      title: "Status",
      render: (_, record) => {
        if (record.Ativo === 1) return "Ativo"

        return "Inativo"
      },
    },
    {
      title: "DataHora Geração",
      render: (_, record) => {
        if (record.DtInc)
          return format(parseISO(record.DtInc), "dd/MM/yyyy", {
            locale: ptBR,
          })
      },
      dataIndex: "DtInc",
    },
    {
      title: "Tipo",
      dataIndex: "NomeRelatorioTipo",
    },
    {
      title: "Mês Ref",
      render: (_, record) => {
        const strArr = record.MesReferencia.split("-")
        const date = new Date(
          Number(strArr[0]),
          Number(strArr[1]) - 1,
          Number(strArr[2]),
        )
        // const newDate = addDays(date, 1)
        return format(date, "MMMM", {
          locale: ptBR,
        })
      },
    },
    {
      title: "Data Início",
      render: (_, record) => {
        const strArr = record.DataInicio.split("-")
        const date = new Date(
          Number(strArr[0]),
          Number(strArr[1]) - 1,
          Number(strArr[2]),
        )
        // const newDate = addDays(date, 1)
        return `${format(date, "dd/MM/yyyy")}`
      },
    },
    {
      title: "Data Corte",
      render: (_, record) => {
        const strArr = record.DataCorte.split("-")
        const date = new Date(
          Number(strArr[0]),
          Number(strArr[1]) - 1,
          Number(strArr[2]),
        )
        // const newDate = addDays(date, 1)
        return `${format(date, "dd/MM/yyyy")}`
      },
    },
  ]

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Selecionar Relatório",
      children: (
        <Table
          columns={selectionColumns}
          dataSource={reportDataExtract}
          size="small"
          pagination={false}
        />
      ),
    },
    {
      key: "2",
      label: "Criar Relatório",
      children: (
        <>
          <Form layout="horizontal" onFinish={handleSubimtReport}>
            <Form.Item label="Tipo de Relatório" rules={[{ required: true }]}>
              <Select
                placeholder="Selecione"
                onFocus={() => clearErrors("IdRelatorioTipo")}
                onSelect={handleSelect}
                options={reportsType}
              />
            </Form.Item>
            <Form.Item label="Mês Referência" rules={[{ required: true }]}>
              <DatePicker
                picker="month"
                placeholder="Selecione o período"
                style={{ width: "100%" }}
                onFocus={() => clearErrors("MesReferencia")}
                onChange={(_, dateString) =>
                  handleChangeDate("MesReferencia", dateString)
                }
              />
            </Form.Item>
            <Space>
              <Form.Item label="Data Início" rules={[{ required: true }]}>
                <DatePicker
                  placeholder="Início"
                  picker="date"
                  onFocus={() => clearErrors("DataInicio")}
                  onChange={(_, dateString) =>
                    handleChangeDate("DataInicio", dateString)
                  }
                />
              </Form.Item>
              <Form.Item label={"Data Corte"} rules={[{ required: true }]}>
                <DatePicker
                  placeholder="Corte"
                  picker="date"
                  onFocus={() => clearErrors("DataCorte")}
                  onChange={(_, dateString) =>
                    handleChangeDate("DataCorte", dateString)
                  }
                />
              </Form.Item>
            </Space>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Criar relatório
              </Button>
            </Form.Item>
          </Form>
        </>
      ),
    },
  ]

  const baseColumns = useMemo(() => {
    const basesColumns: ColumnsType<IReport> = [
      {
        title: "Base",
        dataIndex: "label",
        align: "left",
      },
      {
        title: "Status",
        align: "left",
        render: (_, record) => {
          const status = {
            "Aguardando arquivo": "default",
            "Aguardando envio": "processing",
            processando: "processing",
            Concluído: "success",
            erro: "error",
          }[record.status]

          if (record.status === "processando")
            return (
              <Tag color={status} icon={<SyncOutlined spin />}>
                {record.status}
              </Tag>
            )

          return <Tag color={status}>{record.status}</Tag>
        },
      },
      {
        title: "Importar Arquivo",
        align: "center",
        render: (_, record) => {
          return (
            <CustomUpload
              onChange={(event) => handleChangeFile(record.id, event)}
              key={record.id.toString()}
              record={record}
              disabled={disableCustomUpload}
            />
          )
        },
      },
      {
        title: "Ação",
        align: "center",
        render: (_, record) => (
          <Space>
            <Button
              onClick={() => handleUploadFile(record.id)}
              disabled={!!!record.file}
            >
              {record.total > 0 ? "Renviar Arquivo" : "Importar Arquivo"}
            </Button>
          </Space>
        ),
      },
    ]

    if (selectedRow)
      basesColumns.splice(3, 0, {
        title: "Status Consolidado",
        align: "center",
        render: (_, record) => {
          if (record.total === 0)
            return <Tag color={"warning"}>Não importado</Tag>
          if (record.total > 0) return <Tag color={"success"}>Importado</Tag>

          return
        },
      })

    return basesColumns
  }, [selectedRow, disableCustomUpload, values.reports])

  // console.log({enableConsolidation , isAvailableGeneration})

  return (
    <AppLayout title={["Importar Bases"]} selectedKeys={["3"]}>
      {contextHolder}
      <S.Wrapper $marginBottom="30px">
        <Steps
          size="small"
          current={currentStep}
          items={[
            {
              title: "Criar/Selecionar Relatório Histórico",
            },
            {
              title: "Importar Bases",
            },
            {
              title: "Consolidar Bases Importadas",
            },
          ]}
        />
      </S.Wrapper>
      <S.Wrapper $flexDirection="column">
        <Divider orientation="left">1 - Relatório Histórico</Divider>
        <Tabs
          activeKey={activeTab}
          items={items}
          onChange={onChangeTab}
          style={{ minWidth: 350, width: "100%", maxWidth: 650 }}
        />

        <Divider orientation="left">2 - Importar Base</Divider>
        <Space wrap size={"large"} align="start">
          <Table
            bordered
            columns={baseColumns}
            dataSource={values.reports}
            pagination={false}
          />
        </Space>
        <Form.Item>
          <Checkbox onChange={onChangeConsolidate}>
            habilitar "CONSOLIDAÇÃO"
          </Checkbox>
        </Form.Item>

        <Divider orientation="left">3 - Consolidar Bases Importadas</Divider>
        <Button
          type="primary"
          disabled={!enableConsolidation}
          onClick={handleClientsParticipants}
          loading={consolidateLoading}
        >
          Consolidar
        </Button>
      </S.Wrapper>

      <Modal
        title={"Selecione o relatório para ser utilizado"}
        footer={() => (
          <>
            <Button
              type="default"
              onClick={() => handleReportSelect("created")}
            >
              Relatorio Criado
            </Button>
            <Button
              type="primary"
              onClick={() => handleReportSelect("selected")}
            >
              Relatorio Selecionado
            </Button>
          </>
        )}
        open={openModal}
        onCancel={() => setOpenModal(false)}
      >
        <Typography>
          Antes de importar as bases, selecione o tipo de relatorio que deseja
          seguir
        </Typography>
        <Typography>
          O Relatório que criou ou o Relatório que selecionou
        </Typography>
      </Modal>
    </AppLayout>
  )
}

export default memo(InsertBase)
