import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { io, Socket } from "socket.io-client"
import { setMessage } from "../../store/message/message.slice"

export function EventsGatway() {
  const dispatch = useAppDispatch()

  const { data: authData } = useAppSelector((state) => state.auth)

  const [, setSocket] = useState<Socket | null>(null)

  useEffect(() => {
    if (!authData?.userId) return

    const userId = authData?.userId

    const SOCKET_URL = "https://vipizz-hml.omegacotech.com.br/reports"

    // Conectar ao WebSocket e passar o userId na query string
    const socketConnection = io(SOCKET_URL, {
      transports: ["polling"],
      query: { userId },
      path: "/socket.io",
    })

    // Salvar a conexão no estado
    setSocket(socketConnection)

    // Listener para receber mensagens do servidor
    socketConnection.on(
      "sendReport",
      (data: { file: string; fileName: string }) => {
        const link = document.createElement("a")
        link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data.file}`
        link.download = data.fileName
        link.click()

        dispatch(
          setMessage({
            message: "O arquivo está finalizado e processando o download.",
            type: "success",
          }),
        )
      },
    )

    // Limpar a conexão ao desmontar o componente
    return () => {
      socketConnection.disconnect()
    }
  }, [authData?.userId])

  return null
}
